
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";


export default function PromocionEditar(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [usuario] = useCookies(['usuarioadmi']);
  const [values, setValues] = useState({ 
    activo: '',
    id: '',
    nombre: '',
    codigo: '',
    porcentaje: '',
    monto: '',
    minimo: '',
    comentario: '',
    capacidad: '',
    inicio: '',
    fin: '',
    idusuario: '',
   
  });
  useEffect(() => {

    async function fetchProducto() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id })
      };
  
      const response = await fetch(`${api}/api/promocion/promo`, requestOptions);
      const data = await response.json();
      setValues(data); 
      setLoading(false)
      
    }

    fetchProducto();
console.log(values.idcategoria)
   

  }, []);

  const handleSubmit =  async (event) =>{
    event.preventDefault();
    setIsLoading(true)
    const formData = new FormData();
  
   formData.append('id', values.id);
   formData.append('activo', values.activo);
   // formData.append('nombre', values.nombre);
    formData.append('codigo', values.codigo);
    formData.append('porcentaje', values.porcentaje);
    formData.append('monto', values.monto);
    formData.append('minimo', values.minimo);
    formData.append('comentario', values.comentario);
    formData.append('capacidad', values.capacidad);
    //formData.append('inicio', values.inicio);
    formData.append('fin', values.fin);
    formData.append('idusuario', usuario.usuarioadmi.id);
   // formData.append('stock', values.stock);

    const getData = async () => {


      try {
        const response = await axios.put(`${api}/api/promocion/editar`, formData);
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);
          notification.open({
            message: 'Notificación',
            description: 'Actualización exitosa',
            icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          });
          navigate(-1);
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
    notification.open({
      message: "Ocurrio un error 😢",
      description: `${error.response?.data || error.message}`,
      icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
    });
      }
    }


    await getData();
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'file') {
      setSelectedImage(event.target.files[0]);
    setValues((prevState) => ({ ...prevState, img: event.target.files[0] }));
    } else {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const formatDate = (dateValue) => {
    if (!dateValue) return ''; // Manejar el caso donde dateValue no está definido
  
    const date = new Date(dateValue);
  
    if (isNaN(date.getTime())) {
      // Si no es un valor de fecha válido, maneja el error o devuelve un valor por defecto
      return '';
    }
  
    return date.toISOString().split('T')[0];
  };

  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      <div className="pagetitle">
        <h1>Editar Promoción</h1>
        {/* <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item">Forms</li>
            <li className="breadcrumb-item active">Elements</li>
          </ol>
        </nav> */}
      </div>
      {registroExitoso && (
        <div className="alert alert-success" role="alert">
          ¡Actualización exitosa!
        </div>
      )}
      <section className="section">
        <div className="row">
          <div className="col-lg-6">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Editar Promoción</h5>
                {loading ? (
          <div className="center">  <div className=" spinner"></div></div>
        
        ) :

                <form onSubmit={handleSubmit}>
             {/*      <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Nombre</label>
                    <div className="col-sm-4">
                      <input type="text" className="form-control" name="nombre" value={values.nombre}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div> */}

                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label"> Activo</label>
                    <div className="col-sm-4 form-check form-switch d-flex justify-content-start">
                      <input className="form-check-input" type="checkbox" name="activo" id="gridCheck2"
                        checked={values.activo } onSubmit={handleSubmit}
                        onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputEmail" className="col-sm-2 col-form-label">Código</label>
                    <div className="col-sm-4">
                      <input type="text" className="form-control" name="ingredientes" value={values.codigo}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Porcentaje Tope</label>
                    <div className="col-sm-4 input-group">
                    <span class="input-group-text">%</span>
                      <input type="number" className="form-control" name="precio" required 
                      value={values.porcentaje} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="formFile" className="col-sm-2 col-form-label">Tope Descuento</label>
                    <div className="col-sm-4 input-group">
                    <span class="input-group-text">$</span>
                      <input type="number" className="form-control" name="precio" required 
                      value={values.monto} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Consumo Minimo</label>
                    <div className="col-sm-4 input-group">
                    <span class="input-group-text">$</span>
                      <input type="number" className="form-control" name="stock"
                      value={values.minimo} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                    
                  </div>

                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Capacidad</label>
                    <div className="col-sm-4">
                      <input type="number" className="form-control" name="stock"
                      value={values.capacidad} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                    
                  </div>
           {/*       
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Fecha Inicio</label>
                    <div className="col-sm-4">
                    <input 
                            type="date" 
                            className="form-control" 
                            name="inicio"
                            value={formatDate(values.inicio)} 
                            onChange={handleChange}
                          />
                    </div>
                   
                  </div> */}
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Vencimiento</label>
                    <div className="col-sm-4">
                    <input 
                          type="date" 
                          className="form-control" 
                          name="fin"
                          value={formatDate(values.fin)} 
                          onChange={handleChange}
                        />
                    </div>
                    
                  </div>
                 
                 
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Comentario</label>
                    <div className="col-sm-4">
                      <input type="number" className="form-control" name="stock"
                      value={values.comentario} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                    
                  </div>
                  <div className="text-center ">
                  {isLoading ? <button className="btn btn-primary   rounded-pill mr-2" ><div className="loading d-block isLoading"></div></button> :  <button type="submit" className="btn btn-primary rounded-pill mr-2">
                      Actualizar
                    </button>
                   } 
                    <Link to="/Admin/productos">
                    <button  className="btn btn-primary rounded-pill">
                      Volver
                    </button></Link>
                  </div>

                </form>
                 }

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
