import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../context/AppContext";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


export default function Adicionales({ idproducto, onSelectedItemsChange }) {

    const { active, api } = useContext(AppContext);
    const [productos, setProductos] = useState([]);
    const [usuario] = useCookies(['usuario']);
    const [productoTipo, setProductoTipo] = useState('');
    const [subtipo, setSubtipo] = useState([]);
    const [nombreProducto, setNombreProducto] = useState('');
    const [costoProducto, setCostoProducto] = useState('');
    const [unimax, setUnimax] = useState('');
    const [selectedSubtipo, setSelectedSubtipo] = useState('');
    const [selectedSubtipos, setSelectedSubtipos] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [validate, setValidate] = useState(false);
    const [ingrediente, setIngrediente] = useState([]);

    const fetchSubtipo = async () => {

        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                //body: JSON.stringify({ idusuario: usuario.usuario.id })
            };

            const response = await fetch(`${api}/api/ingrediente/subtipo/${usuario.usuario.id}`, requestOptions);
            const data = await response.json();
            setSubtipo(data);

            if (idproducto) {
                const request = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    //body: JSON.stringify({ id: idproducto})
                };

                const responseadi = await fetch(`${api}/api/productos/adicionales/` + Number(idproducto), request);
                const adicionales = await responseadi.json();
                //const idsIngredientes = adicionales.map(adicional => adicional.idingrediente);
                handleItemSelect(adicionales)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {


        fetchSubtipo();

    }, []);

    const handleAlta = async (event) => {

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setValidate(true)

        if (nombreProducto.trim() === '' || productoTipo.trim() === '') return;
        if (productoTipo === "OPCIONAL" && costoProducto.trim() === "") return;

        const subtipoSeleccionado = subtipo.find(sub => sub.id === Number(selectedSubtipo));
        const ingrediente = {
            nombre: nombreProducto,
            tipo: productoTipo,
            subtipo: subtipoSeleccionado,
            costo: costoProducto,
            maximo: unimax,
            idusuario: usuario.usuario.id
        };

        try {
            const response = await axios.post(`${api}/api/ingrediente/alta`, ingrediente, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.data;
            if (response.status === 200 || response.status === 201) {
                notification.open({
                    message: 'Notificación',
                    description: 'Registro exitoso',
                    icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                });
            }

        } catch (error) {
            notification.open({
                message: "Notificacion ",
                description: "Ocurrio un error 😢",
                icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
            });
        }
        setValidate(false)
        fetchProducto();

        handleAgregarProducto()

    };

    // Maneja la adición de nuevos productos a la lista
    const handleAgregarProducto = () => {
        if (nombreProducto.trim() === '') return; // Verifica que el nombre no esté vacío
        const subtipoSeleccionado = subtipo.find(sub => sub.id === Number(selectedSubtipo));

        const nuevoProducto = {
            subtipo: subtipoSeleccionado,
            tipo: productoTipo,
            nombre: nombreProducto,
            costo: costoProducto || 'N/A', // Si no tiene costo, establece 'N/A'
        };

        setProductos([...productos, nuevoProducto]);
        // Limpia los campos del formulario
        setNombreProducto('');
        setCostoProducto('');
        setUnimax('');

    };
    const fetchProducto = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            //body: JSON.stringify({ idusuario: usuario.usuario.id })
        };

        try {

            const response = await fetch(`${api}/api/ingrediente/${usuario.usuario.id}`, requestOptions);
            const data = await response.json();
            setProductos(data);
        } catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {


        fetchProducto();
    }, []);



    // Función para agrupar productos por subtipo
    const agruparPorSubtipo = (productos) => {
        return productos.reduce((acc, producto) => {
            const { tipo, subtipo, maximo } = producto;
            if (!acc[tipo]) {
                acc[tipo] = {};
            }
            if (!acc[tipo][subtipo?.nombre]) { // Asegúrate de usar la propiedad correcta
                acc[tipo][subtipo?.nombre] = [];
            }
            // Inicializar el agrupamiento por maximo si no existe dentro del subtipo
            if (!acc[tipo][subtipo?.nombre][maximo]) {
                acc[tipo][subtipo?.nombre][maximo] = [];
            }

            acc[tipo][subtipo?.nombre][maximo].push(producto);
            return acc;
        }, {});
    };

    const handleSubtipoSelect = (tipo, subtipo, maximo, productosDelSubtipoMaximo) => {
        const subtipoKey = `${tipo}-${subtipo}-${maximo}`; // Crear una clave única combinando tipo y subtipo

        if (selectedSubtipos.includes(subtipoKey)) {
            // Si ya está seleccionado, desmarcar todos los productos de ese subtipo y maximo
            setSelectedSubtipos(selectedSubtipos.filter((s) => s !== subtipoKey));
            setSelectedItems(selectedItems.filter((item) => !productosDelSubtipoMaximo.some((producto) => producto.id === item)));
        } else {
            // Seleccionar todos los productos de ese subtipo y maximo
            setSelectedSubtipos([...selectedSubtipos, subtipoKey]);
            setSelectedItems([...selectedItems, ...productosDelSubtipoMaximo.map(producto => producto.id)]);
        }
    }

    // Manejar selección individual de ingredientes
    const handleItemSelect = (ingredientes) => {
        if (Array.isArray(ingredientes)) {
            const nuevosSeleccionados = [...selectedItems];

            ingredientes.forEach((ingrediente) => {
                const exists = nuevosSeleccionados.some(item => item.id === ingrediente.idingrediente);
                if (!exists) {
                    nuevosSeleccionados.push(ingrediente.idingrediente);
                }
            });

            setSelectedItems(nuevosSeleccionados);
        } else {
            const exists = selectedItems.some(item => item === ingredientes);
            if (exists) {
                setSelectedItems(selectedItems.filter((item) => item !== ingredientes));
            } else {
                setSelectedItems([...selectedItems, ingredientes]);
            }
        }

    };

    const productosAgrupados = agruparPorSubtipo(productos);



    useEffect(() => {
        // Llama a la función del padre y pasa el estado actualizado
        onSelectedItemsChange(selectedItems);
    }, [selectedItems])

    const [newCategoryName, setNewCategoryName] = useState('');

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        setSelectedSubtipo(value);

        // Si se selecciona "nuevo", se limpia el nombre de la nueva categoría
        if (value === "nuevo") {
            setNewCategoryName('');
        }
    };

    const handleSubmitNewCategory = async () => {
        if (newCategoryName.trim() === '') {
            alert("Por favor, ingresa un nombre para la nueva categoría.");
            return;
        }

        try {
            const response = await fetch(`${api}/api/ingrediente/subtipo/alta`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nombre: newCategoryName, idusuario: usuario.usuario.id }),
            });

            if (!response.ok) {
                throw new Error('Error al agregar la nueva categoría');
            }

            const result = await response.status;
            notification.open({
                message: 'Notificación',
                description: 'Registro exitoso',
                icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
            });
            setSelectedSubtipo("")
            fetchSubtipo();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mt-2 border rounded p-2 bg-white   ">
            <div class="accordion " id="accordionPanelsStayOpenExample border  rounded list-group-item ">
                <div class="accordion-item bg-white-custom ">
                    <h2 class="accordion-header bg-white-custom ">
                        <button
                            class="accordion-button bg-light flex  text-dark"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                        >
                            <strong className="pr-1">Alta Ingrediente: </strong>   Requerido / Opcional o Extra / Tamaño
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show bg-white text-dark"
                    >
                        <div class="accordion-body ">
                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label">Tipo:</label>
                                <div className="col-sm-5">
                                    <select
                                        className="form-select "
                                        value={productoTipo}
                                        onChange={(e) => setProductoTipo(e.target.value)}
                                        required={validate}
                                    >
                                        <option value="">Elegir Tipo</option>
                                        <option value="REQUERIDO">REQUERIDO</option>
                                        <option value="OPCIONAL">OPCIONALES / EXTRA</option>
                                    </select></div>
                            </div>


                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label">Categoría:</label>
                                <div className="col-sm-5">
                                    <select
                                        className="form-select"
                                        value={selectedSubtipo}
                                        onChange={handleCategoryChange}
                                        required={validate}
                                    >
                                        <option value="">Elegir una categoría</option>
                                        {Array.isArray(subtipo) && subtipo.map((sub) => (
                                            <option key={sub.id} value={sub.id}>{sub.nombre}</option>
                                        ))}
                                        <option value="nuevo">+ Nueva categoría</option> {/* Opción para nueva categoría */}
                                    </select>

                                    {selectedSubtipo === "nuevo" && (
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nombre de la nueva categoría"
                                                value={newCategoryName}
                                                onChange={(e) => setNewCategoryName(e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-primaryMenu mt-2"
                                                onClick={handleSubmitNewCategory}
                                            >
                                                Agregar Categoría
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label">Nombre:</label>
                                <input
                                    className="form-control col-sm-5"
                                    type="text"
                                    value={nombreProducto}
                                    onChange={(e) => setNombreProducto(e.target.value)}
                                    required={validate}
                                />
                            </div>
                            {productoTipo === "REQUERIDO" ?
                                <div className="row mb-3">

                                    <label className="col-sm-2 col-form-label">Max. Uni.:</label>
                                    <input
                                        className="form-control col-sm-5"
                                        type="number"
                                        value={unimax}
                                        placeholder="Unidades Máximas Permitidas"
                                        onChange={(e) => setUnimax(e.target.value)}
                                        required={validate}
                                    />
                                </div> :
                                <div className="row mb-3">

                                    <label className="col-sm-2 col-form-label">Precio:</label>
                                    <input
                                        className="form-control col-sm-5"
                                        type="number"
                                        value={costoProducto}
                                        placeholder=""
                                        onChange={(e) => setCostoProducto(e.target.value)}
                                        required={validate}
                                    />
                                </div>
                            }
                            <div className="center">
                                <button className=" btn btn-primaryMenu" onClick={(e) => handleAlta(e)}>
                                    Agregar Producto
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="container mt-2 row mb-2 text-center">
                <h5 className="card-title">Lista de Ingredientes Registrados:</h5>

                {Object.keys(productosAgrupados).map((tipo, tipoIndex) => (
                    <div key={tipoIndex} className="mb-1">
                        <h5 className="text-left m-0"><strong>{tipo}</strong></h5>

                        {/* Iterar sobre subtipo */}
                        {Object.keys(productosAgrupados[tipo]).map((subtipo, subtipoIndex) => {
                            return Object.keys(productosAgrupados[tipo][subtipo]).map((maximo, maximoIndex) => {
                                const subtipoKey = `${tipo}-${subtipo}-${maximo}`;
                                return (
                                    <div key={maximoIndex} className="mb-2">
                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center justify-content-between bg-light p-2 border rounded">
                                                    <h6 className="text-left m-0"><strong>{subtipo}{maximo > 0 && <p className="text-muted"> Máximo permitido: {maximo}</p>}</strong></h6>
                                                    
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={selectedSubtipos.includes(subtipoKey)}
                                                            onChange={() => handleSubtipoSelect(tipo, subtipo, maximo, productosAgrupados[tipo][subtipo][maximo])}
                                                        />
                                                        
                                                        <label className="form-check-label">Seleccionar todos</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ul className="list-group">
                                            {/* Iterar sobre productos con ese valor de maximo */}
                                            {productosAgrupados[tipo][subtipo][maximo].map((producto, productoIndex) => (
                                                <li className="list-group-item flex" key={productoIndex}>
                                                    <strong>{producto.nombre}</strong> ({producto.tipo}) |{' '}
                                                    {producto.tipo === 'REQUERIDO' ? (
                                                        <>
                                                            Max. Uni: <strong>{producto.maximo}</strong>
                                                        </>
                                                    ) : (
                                                        <>
                                                            Precio Extra: <strong>{producto.costo}</strong>
                                                        </>
                                                    )}
                                                    <input
                                                        className="ml-4"
                                                        type="checkbox"
                                                        checked={selectedItems.some((item) => item === producto.id)}
                                                        onChange={() => handleItemSelect(producto.id)}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                );
                            });
                        })}
                    </div>
                ))}
            </div>
        </div>
    )
}