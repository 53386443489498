
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';
import useInitialState from "../hooks/useInitialState";
import "../vendor/bootstrap/css/bootstrap.min.css";
import "../vendor/boxicons/css/boxicons.min.css";
import "../vendor/quill/quill.snow.css";
import "../vendor/quill/quill.bubble.css";
import "../vendor/bootstrap-icons/bootstrap-icons.css";
import "../vendor/simple-datatables/style.css";
import '../assets/css/AdminApp.css';
import Tables from "../page/pageAdmin/tables";
import ProductsTable from "../page/pageAdmin/productsTable"
import Nav from "../utils/nav";
import Aside from "../utils/aside";
import ProductsAlta from "../page/pageAdmin/altaproduct";
import ClienteAlta from "../page/pageAdmin/altacliente";
import ClienteEditar from "../page/pageAdmin/updatecliente";
import UsuariosTable from "../page/pageAdmin/UsuariosTable";
import MesaAlta from "../page/pageAdmin/altamesa";
import Grafic from "../page/pageAdmin/grafic";
import Pedidos from "../page/pageAdmin/pedido";
import ProductoEditar from "../page/pageAdmin/updateproducto";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from "../components/ErrorFallback";
import Register from "../page/pageAdmin/register";
import Navbar from "../utils/navbar";
import Login from "../page/pageAdmin/login";
import Error from "../components/error";
import AppContext from "../context/AppContext";
import Img from "../page/pageAdmin/img";
import PromocionAlta from "../page/pageAdmin/altapromocion";
import MeseroAlta from "../page/pageAdmin/altamesero";
import MeseroTable from "../page/pageAdmin/meseroTable";
import PromocionTable from "../page/pageAdmin/promocionTable";
import PromocionEditar from "../page/pageAdmin/updatepromocion";
import User from "../page/pageAdmin/user";
import QR from "../page/pageAdmin/qr";


export default function AdminApp() {
 // const registroExitoso = sessionStorage.getItem('registroExitoso');
 
 const registroString = localStorage.getItem('registroExitoso');

 const navigate = useNavigate();
 useEffect(() => {
 if (!registroString) {
  localStorage.removeItem('registroExitoso'); 
    navigate('Admin/login');
 }
 if (registroString) {
 const { value, expirationTime } = JSON.parse(registroString);  
const currentTime = new Date().getTime();

  if (currentTime > expirationTime) {
    localStorage.removeItem('registroExitoso'); // Eliminar el valor expirado
    navigate('Admin/login');
  };}



}, [navigate, registroString  ]);
  const clickMenu = () => {
    localStorage.removeItem('Admin')
    window.location.replace('/')
    };

  const handleError = (error, info) => {
    // Aquí puedes realizar acciones adicionales, como enviar el error a un servicio de registro.
    console.error(error, info);
  };

  const renderView = () => registroString  ?
  <>

    <Navbar></Navbar>
    <Aside></Aside>


    <Routes>

      <Route index path="/Admin/mesas" element={<ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}><Tables className="active" /></ErrorBoundary>} />
      <Route exact path='/Admin/productos' element={<ErrorBoundary><ProductsTable className="active" /></ErrorBoundary>} />
      <Route exact path='/Admin/meseros' element={<ErrorBoundary><MeseroTable className="active" /></ErrorBoundary>} />
      <Route exact path='/Admin/productoalta' element={<ErrorBoundary><ProductsAlta /></ErrorBoundary>} />
      <Route exact path='/Admin/clientealta' element={<ErrorBoundary><ClienteAlta /></ErrorBoundary>} />
      <Route exact path='/Admin/meseroalta' element={<ErrorBoundary><MeseroAlta/> </ErrorBoundary>} />
      <Route exact path='/Admin/mesaalta' element={<ErrorBoundary><MesaAlta /></ErrorBoundary>} />
      <Route exact path='/Admin/promocionalta' element={<ErrorBoundary><PromocionAlta /></ErrorBoundary>} />
      <Route exact path='/Admin/promociones' element={<ErrorBoundary><PromocionTable/></ErrorBoundary>} />
      <Route exact path='/Admin/editarpromocion/:id' element={<ErrorBoundary><PromocionEditar/></ErrorBoundary>} />
      <Route exact path='/Admin/editarcliente/:id' element={<ErrorBoundary><ClienteEditar /></ErrorBoundary>} />
      <Route exact path='/Admin/editarproducto/:id' element={<ErrorBoundary><ProductoEditar /></ErrorBoundary>} />
      <Route exact path='/Admin/usuarios' element={<ErrorBoundary><UsuariosTable></UsuariosTable></ErrorBoundary>} />
      <Route exact path='/Admin/graficos' element={<ErrorBoundary><Grafic></Grafic></ErrorBoundary>} />
      <Route index path='/Admin' element={<ErrorBoundary><Pedidos></Pedidos></ErrorBoundary>} />
      <Route exact path='/Admin/img' element={<ErrorBoundary><Img></Img></ErrorBoundary>} />
      <Route exact path='/Admin/qr' element={<ErrorBoundary><QR></QR> </ErrorBoundary>} />
      <Route exact path='/Admin/error' element={<ErrorBoundary><Error></Error></ErrorBoundary>} />
      <Route exact path='/Admin/perfil' element={<ErrorBoundary> <User></User> </ErrorBoundary>} />
      <Route exact path='/Admin/Registro' element={<ErrorBoundary><Register></Register></ErrorBoundary>} />   
    </Routes>
  </>

  : <Routes>
 
    <Route index path='/Admin' element={<ErrorBoundary><Login></Login></ErrorBoundary>} />
    <Route exact path='/Admin/error' element={<ErrorBoundary><Error></Error></ErrorBoundary>} />
    <Route path='/Admin/Login' element={<Login></Login>} />
  </Routes> ;


  
  return (

    <div className='App admi' id="bodyAdmi">


      { renderView() }

      <footer className="footer">
        <div className="copyright">
          &copy; Copyright <strong><span onClick={() => clickMenu()}>RestoAdmin</span></strong>. All Rights Reserved
        </div>
        <div className="credits">

          Designed by <a >Jeampierre Gonzalez</a>
        </div>
      </footer>
    </div>

  )
}