import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../context/AppContext";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export default function MeseroAlta() {
  const { active, api } = useContext(AppContext);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState("");
  const [usuario] = useCookies(["usuarioadmi"]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const mesero = {
      nombre: formData.get('nombre'),
      apellido: formData.get('apellido'),
      email: formData.get('email'),
      password: formData.get('password'),
      telefono: formData.get('telefono'),
      activo: formData.get('activo'),
      idusuario: usuario.usuarioadmi.id,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${api}/api/mesero/alta`, {
          method: "POST",
          body: JSON.stringify(mesero),
          headers: {
           'Content-Type': 'application/json'
          },
        });
        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);
          event.target.reset();
        }
      } catch (error) {
     
        notification.open({
          message: "Ocurrio un error 😢",
          description: `${error.response?.data || error.message}`,
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });
      }
    };

    await getData();
  };

  useEffect(() => {
    if (registroExitoso) {
      notification.open({
        message: "Notificación",
        description: "Registro exitoso",
        icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
      });
    }
  }, [registroExitoso]);

  return (
    <main id="main" className={active === "active" ? "main active" : "main"}>
      {/* <div className="pagetitle">
        <nav>
          <ol className="breadcrumb"> <h1>Altas Productos</h1>
        </ol>
        </nav>
      </div> */}

      <span className="rigth">
        <i className="bi bi-arrow-return-left"></i>
      </span>
      <section className="section">
        <div className="row">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Alta Mesero</h5>

                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">
                      Activo
                    </label>
                    <div className="col-sm-5 form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="activo"
                        id="gridCheck2"
                        value="true"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">
                      Nombres
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control"
                        name="nombre"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputEmail" className="col-sm-2 col-form-label">
                      Apellidos
                    </label>

                    <div className="col-sm-5">
                      <input className="form-control" name="apellido" required />
                      {/* placeholder="Descripciones"  */}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">
                      Email
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="mail"
                        className="form-control"
                        name="email"
                        autocomplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="password" className="col-sm-2 col-form-label">
                      Contraseña
                    </label>
                    <div className="col-sm-5">
                      <input
                        className="form-control"
                        type="password"
                        id="password"
                        name="password"
                         pattern="^\d{6}$"
                        title="La contraseña debe tener 6 caracteres, sólo númerico."
                        autocomplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">
                      Telefono
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="telefono"
                        className="form-control"
                        name="telefono"
                      />
                    </div>
                    {/* <input type="hidden" name="idUsuario" value="17" /> */}
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary rounded-pill">
                      Registrar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
